// required imports

import ImageViewer from '../ui/ImageViewer';
import { Button, IconButton } from '@library/buttons';
import { formatDatetime } from 'modules/shared/helpers/shared.utils';
import { CrossIcon, CalendarIcon, VideoIcon, DownloadIcon, DeleteIcon } from '@icon/index';
import { FC, useCallback, useRef, useState } from 'react';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { ICamera, ICameraImage, IClient, IProject } from '@api/models';
import useCameraViewDimensions from '../../hooks/use-camera-view-dimensions';
import OverlayImageSlider from 'modules/shared/components/OverImageSlider/OverlayImageSlider';
import { Tooltip } from '@library/tooltip';
import { Link, useNavigate } from 'react-router-dom';
//import OverlayImageSlider from 'modules/dashboard/components/image-slider/OverlayImageSlider'; // important

// import { toast } from 'react-toastify';
// import Toolbar from '../toolbar/Toolbar';
// import { Tooltip } from 'library/tooltip';
// import { useNavigate } from 'react-router-dom';
// import { CircularProgress } from 'library/loaders';
// import WeatherDrawer from '../weather/WeatherDrawer';
// import ToolbarToggler from '../toolbar/ToolbarToggler';
// import useDialog from 'modules/shared/hooks/use-dialog';
// import PrintShotView from '../print-shot/PrintShotView';
// import useWeatherQuery from '../../hooks/use-weather-query';
// import useCSSVariables from 'modules/shared/hooks/use-css-variables';
// import WeatherWidget from 'modules/dashboard/components/ui/WeatherWidget';
// import EventBlocker from 'modules/shared/components/helpers/EventBlocker';
// import InfoOverlayBox from 'modules/shared/components/helpers/InfoOverlayBox';
// import { DesktopMediaQuery, useDesktopMediaQuery } from 'library/media-query';
// import useCameraViewDimensions from 'modules/dashboard/hooks/use-camera-view-dimensions';
// import useCameraImageCommentCountQuery from '../../hooks/use-camera-image-comment-count-query';

interface CameraDetailViewerProps {
    camera: ICamera;
    project: IProject;
    images: ICameraImage[];
    selectedImage: ICameraImage;
    onSelectedImageChange: (image: ICameraImage) => void;
    selectedDate: string;
    onSelectedDateChange: (date: string) => void;
    startDate: string;
    endDate: string;
    isLoading: boolean;
    offsetTop: number;
}
const CameraDetailViewer = (props: CameraDetailViewerProps) => {
    const {
        camera,
        project,
        images,
        selectedImage,
        onSelectedImageChange,
        selectedDate,
        onSelectedDateChange,
        startDate,
        endDate,
        isLoading,
        offsetTop,
    } = props;

    // const { user } = useAuthSelector();  // to get image to be used

    const navigate = useNavigate();
    // const printShotDialog = useDialog();
    // const weatherDialog = useDialog();

    const mainRef = useRef(null);

    const [showImageSlider, setShowImageSlider] = useState<boolean>(false);
    const [showToolbar, setShowToolbar] = useState<boolean>(false);

    // const isAIEnabled = useMemo(
    //     () => camera.enabledFeaturesList.includes(CameraFeatureEnum.AI),
    //     [camera.enabledFeaturesList]
    // );

    // const isDesktop = useDesktopMediaQuery();
    // const { imageSliderHeight } = useCSSVariables();

    const isFullScreen = useCallback(() => {
        return Boolean(document.fullscreenElement);
    }, []);

    const { refs, dimensions, containerDimensions } = useCameraViewDimensions({
        aspectRatio: selectedImage?.resolution.width / selectedImage?.resolution.height || 0,
        isFullScreen: isFullScreen(),
        // offsetTop: isDesktop ? offsetTop : offsetTop + imageSliderHeight,
        offsetTop: offsetTop,
    });

    // const { count: imageCommentCount } = useCameraImageCommentCountQuery(project._id, camera._id, selectedImage?.name);

    // const weatherQuery = useWeatherQuery(project._id, camera._id, selectedImage?.datetime, {
    //     enabled: isAIEnabled,
    // });

    // const handleToolbarOpen = useCallback(() => {
    //     setShowToolbar(true);
    //     setShowImageSlider(false);
    // }, []);

    const handleImageSliderToggle = useCallback(() => {
        setShowImageSlider((prev) => !prev);
        setShowToolbar(false);
    }, []);

    // const handleToggleFullScreen = useCallback(() => {
    //     if (!isFullScreen()) {
    //         mainRef.current?.requestFullscreen().catch(() => {
    //             toast.error('Could not open in full screen. Please try again');
    //         });
    //     } else {
    //         document.exitFullscreen();
    //     }
    // }, [isFullScreen]);

    // const imageUrl = useMemo(() => getPreferedImageUrl(user, selectedImage), [user, selectedImage]);

    const imageUrl = selectedImage?.url4k || '';

    return (
        <div ref={mainRef}>
            <div className="relative">
                <div ref={refs.setReference}>
                    <ImageViewer
                        src={imageUrl}
                        orientation={camera.orientation as string}
                        width={dimensions.width}
                        height={dimensions.height}
                        containerHeight={containerDimensions.height}
                    />
                </div>

                <div className="absolute top-6 left-6 z-[9]">
                    <div className="flex items-start gap-4">
                        {/* <AnimatePresence initial={false} mode='wait'>
                            {!showToolbar ? (
                                <ToolbarToggler onClick={handleToolbarOpen} />
                            ) : (
                                <Toolbar
                                    onClose={() => setShowToolbar(false)}
                                    height={containerDimensions.height - 48}
                                />
                            )}
                        </AnimatePresence> */}

                        {/* <DesktopMediaQuery> */}
                        <EventBlocker stopPropagation>
                            <Button className="bg-white text-grayscale-600 " onClick={handleImageSliderToggle}>
                                <span className="flex items-center justify-center w-4">
                                    {showImageSlider ? (
                                        <CrossIcon className="text-grayscale-600" width="11" height="11" />
                                    ) : (
                                        <CalendarIcon className="text-grayscale-600" width="16" height="18" />
                                    )}
                                </span>

                                {formatDatetime(selectedImage?.datetime, 'h:mm a [·] DD MMM, YYYY')}
                            </Button>
                        </EventBlocker>
                        {/* </DesktopMediaQuery> */}
                    </div>
                </div>

                {/* <div className='absolute top-6 right-20 z-[9]'>
                    <div className='flex items-start gap-4'>
                        <Button color="secondary" >
                            <span className="w-2 h-2 rounded-full bg-success-500"></span>
                            Live view
                            <ChevronDownIcon className="text-grayscale-600" />
                        </Button>

                        {!isFullScreen() && (
                            <DesktopMediaQuery>
                                <div
                                    className={isAIEnabled ? 'cursor-pointer' : ''}
                                    onClick={isAIEnabled ? weatherDialog.show : null}
                                >
                                    {weatherQuery.isLoading ? (
                                        <InfoOverlayBox>
                                            <CircularProgress size={16} />
                                            <span className='text-white'>Loading...</span>
                                        </InfoOverlayBox>
                                    ) : (
                                        <WeatherWidget
                                            weather={weatherQuery.data}
                                            disabled={!isAIEnabled || !weatherQuery.data}
                                        />
                                    )}
                                </div>
                            </DesktopMediaQuery>
                        )}
                    </div>
                </div> */}

                <div className="absolute top-6 right-6 z-[9] space-y-4">
                    <Tooltip placement="left" content={<div className="whitespace-nowrap">Download Image</div>}>
                        <a href={selectedImage?.url4k} target="_blank">
                            <IconButton
                                color="secondary"
                                className="relative bg-white"
                                // onClick={() =>
                                //     navigate(
                                //         `/projects/${(camera.cameraCctv as ICameraCCTV).project}/live-cameras/${
                                //             (camera.cameraCctv as ICameraCCTV)._id
                                //         }`
                                //     )
                                // }
                            >
                                <span className="absolute top-[-3px] right-[-3px] w-[10px] h-[10px] rounded-full bg-success-500"></span>

                                <DownloadIcon className="text-grayscale-600" width="19" height="14" />
                            </IconButton>
                        </a>
                    </Tooltip>
                    <Tooltip
                        placement="left"
                        content={
                            (camera.enabledFeaturesList as string[]).includes('BIM') ? 'BIM Modules' : 'BIM Unavailable'
                        }
                        contentClassName="max-w-sm "
                    >
                        {/* <Link
                            to={`/clients/${camera?.client}/projects/${(camera?.project as IProject)?._id}/cameras/${
                                camera._id
                            }/bim-viewer`}
                        > */}
                        <IconButton
                            color="secondary"
                            className={
                                'relative text-grayscale-600 bg-white'
                                //     [
                                //     (camera.enabledFeaturesList as string[]).includes('BIM')
                                //         ? 'relative text-grayscale-600 bg-white'
                                //         : ' relative text-grayscale-400 bg-[rgba(26,28,31,0.52)] border-none shadow-none !opacity-100',
                                // ].join(' ')
                            }
                            onClick={() =>
                                navigate(
                                    `/clients/${camera?.client}/projects/${
                                        (camera?.project as IProject)?._id
                                    }/cameras/${camera._id}/bim-viewer`
                                )
                            }
                            // disabled={!(camera.enabledFeaturesList as string[]).includes('BIM')}
                        >
                            <span className="text-sm">BIM</span>{' '}
                            {(camera.enabledFeaturesList as string[]).includes('BIM') && (
                                <span className="absolute top-[-3px] right-[-3px] w-[10px] h-[10px] rounded-full bg-success-500"></span>
                            )}
                        </IconButton>
                        {/* </Link> */}
                    </Tooltip>
                    <Tooltip placement="left" content={'Delete Images'} contentClassName="max-w-sm ">
                        <Link
                            to={`/clients/${camera?.client}/projects/${(camera?.project as IProject)?._id}/cameras/${
                                camera._id
                            }/images`}
                        >
                            <IconButton color="secondary" className={'text-grayscale-600 bg-white'}>
                                <DeleteIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>

                    {/* {camera.cameraCctv && (
                        <Tooltip placement='left' content='Live View'>
                            <IconButton
                                color='secondary'
                                className='relative'
                                onClick={() =>
                                    navigate(
                                        `/projects/${(camera.cameraCctv as ICameraCCTV).project}/live-cameras/${
                                            (camera.cameraCctv as ICameraCCTV)._id
                                        }`
                                    )
                                }
                            >
                                <span className='absolute top-[-3px] right-[-3px] w-[10px] h-[10px] rounded-full bg-success-500'></span>

                                <VideoIcon className='text-grayscale-600' width='19' height='14' />
                            </IconButton>
                        </Tooltip>
                    )} */}
                    {/* <Tooltip placement='left' content={isAIEnabled ? 'AI Modules' : 'AI Unavailable'}>
                        <IconButton
                            color='secondary'
                            className={[
                                'relative',
                                isAIEnabled
                                    ? 'text-[#21B36D]'
                                    : 'text-grayscale-400 bg-[rgba(26,28,31,0.52)] border-none shadow-none !opacity-100',
                            ].join(' ')}
                            onClick={() => navigate('ai')}
                            disabled={!isAIEnabled}
                        >
                            {isAIEnabled && (
                                <span className='absolute top-[-3px] right-[-3px] w-[10px] h-[10px] rounded-full bg-success-500'></span>
                            )}
                            <span className='font-semibold'>AI</span>
                        </IconButton>
                    </Tooltip> */}
                    {/* <Tooltip placement='left' content={isFullScreen() ? 'Exit Fullscreen' : 'Fullscreen'}>
                        <IconButton color='secondary' onClick={handleToggleFullScreen}>
                            {isFullScreen() ? (
                                <MinimizeIcon className='text-grayscale-600' width='18' height='18' />
                            ) : (
                                <MaximizeIcon className='text-grayscale-600' width='18' height='18' />
                            )}
                        </IconButton>
                    </Tooltip> */}
                    {/* <DesktopMediaQuery>
                        <Tooltip placement='left' content='Comment'>
                            <IconButton
                                className='relative'
                                color='secondary'
                                onClick={() => navigate(`images/${selectedImage.name}/comments`)}
                            >
                                {imageCommentCount > 0 && (
                                    <span className='absolute inline-flex justify-center items-center w-5 h-5 bg-[#FE5C55] rounded-full top-[-9px] right-[-8px] text-[10px] font-semibold text-white'>
                                        {imageCommentCount >= 10 ? '9+' : imageCommentCount}
                                    </span>
                                )}
                                <CommentIcon className='text-grayscale-600' width='18' height='18' />
                            </IconButton>
                        </Tooltip>

                        <Tooltip placement='left' content='Share'>
                            <IconButton
                                color='secondary'
                                onClick={() => navigate(`images/${selectedImage.name}/share`)}
                            >
                                <UploadIcon className='text-grayscale-600' width='18' height='18' />
                            </IconButton>
                        </Tooltip>

                        <Tooltip placement='left' content='PrintShot'>
                            <IconButton color='secondary' onClick={printShotDialog.show}>
                                <PrintIcon className='text-grayscale-600' width='18' height='18' />
                            </IconButton>
                        </Tooltip>
                    </DesktopMediaQuery> */}
                </div>

                {/* this is importand */}
                <OverlayImageSlider
                    show={showImageSlider}
                    onClose={() => setShowImageSlider(false)}
                    images={images}
                    selectedImage={selectedImage}
                    onSelectedImageChange={onSelectedImageChange}
                    selectedDate={selectedDate}
                    onSelectedDateChange={onSelectedDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    isLoading={isLoading}
                />
            </div>

            {/* <WeatherDrawer show={weatherDialog.isOpen} onClose={weatherDialog.hide} weather={weatherQuery.data} /> */}

            {/* <PrintShotView
                show={printShotDialog.isOpen}
                onClose={printShotDialog.hide}
                name={`${project.name} - ${camera.name}`}
                clientLogoUrl={user.client.logoUrl}
                clientName={user.client.name}
                imageUrl={imageUrl}
                timestamp={formatDatetime(selectedImage?.datetime, 'h:mm a [·] DD MMM, YYYY')}
            /> */}
        </div>
    );
};

export default CameraDetailViewer;
